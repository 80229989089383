$draftail-base-spacing: 0.25rem;

$button-spacing: 0.5rem;
$controls-spacing: $draftail-base-spacing;
$draftail-editor-padding: $button-spacing + $controls-spacing;

$color-white: #fff;
$color-black: #000;
$color-grey: #333;
// Default Draft.js placeholder text color, minus 15% lightness.
$color-grey-525760: #525760;
$color-light-grey: #ddd;

$draftail-editor-text: $color-grey;
$draftail-editor-background: $color-white;
$draftail-editor-readonly-opacity: 0.5;
$draftail-editor-chrome: $color-white;
$draftail-editor-chrome-text: $color-grey;
$draftail-editor-chrome-active: $color-black;
$draftail-editor-chrome-accent: darken(
  $color: $draftail-editor-chrome,
  $amount: 10%,
);
$draftail-tooltip-chrome: $color-grey;
$draftail-tooltip-chrome-text: $color-white;

$draftail-editor-font-family: "Source Sans Pro", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$draftail-editor-font-size: 1rem;
$draftail-editor-line-height: 1.35;

$draftail-placeholder-text: $color-grey-525760;

$draftail-editor-border: 1px solid $color-light-grey;

$draftail-editor-radius: 5px;
$draftail-tooltip-radius: 5px;
$draftail-toolbar-radius: 0;
$draftail-toolbar-tooltip-radius: 4px;
$draftail-toolbar-border-bottom: $draftail-editor-border;

$draftail-toolbar-tooltip-duration: 0.1s;
$draftail-toolbar-tooltip-delay: 1s;

$draftail-block-spacing: $draftail-base-spacing * 2;

$draftail-editor-z-index: 1;
$draftail-tooltip-z-index: $draftail-editor-z-index + 1;
$draftail-overlay-z-index: $draftail-tooltip-z-index + 1;
$draftail-toolbar-z-index: $draftail-overlay-z-index + 1;
$draftail-toolbar-tooltip-z-index: $draftail-toolbar-z-index + 1;

@import "../node_modules/draftail/lib/index";

.Draftail-ToolbarButton__label {
  vertical-align: baseline;
}

.Draftail-ToolbarButton {
  vertical-align: middle;
  padding: 0.4rem 0.65rem;

  &:hover {
    background-color: #f0f0f0;
  }
}
